








































@import '~variables'

.properties-list
  flex-grow 1
  overflow-y auto
  padding-bottom 50px
  height 100%
