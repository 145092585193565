



























































































































































































































































@import '~variables'

.building-configuration
  min-height auto
  height 100%
  .sidebar >>> aside
    display flex
    flex-direction column
    overflow visible
  .building-configuration__building
    padding-top 0.1px
    width 100%
    height 100%
    .nav-info
      margin $space-2 $space-2 0
    .viewer
      position relative
      height calc(100% - 51px)
      background-color $silver
      .building-list, .building-viewer
        height 100%
      .loading__spinner
        stroke black
        fill black
    .loading-container
      display flex
      flex-direction column
      justify-content center
      align-items center
      height 100%
      .loading__message
        padding 15px
        width 100%
        color black
        text-align center
        font-size $fs-h1

.back-button-arrow
  transform rotate(90deg)
.building-preferences
  display flex
  gap 20px
  flex-direction column
  align-items center
  font-size 16px
  margin 24px
  .button-block
    display flex
    flex-direction row
    .info
      width 50px
      text-align center
      padding-top 3px
