

















































































































@import '~variables'

.building-conf-menu
  .building-information
    padding 16px
    background-color $black
    color white
    .building-name
      margin 0
      margin-bottom 16px
      text-align center
      font-weight 700
      font-size $fs-h2
      line-height 1
    .building3d
      display flex
      justify-content center
      align-items center
      height fit-content
    .address
      display flex
      flex-direction column
      font-size $fs-h3
      .address-row
        margin-bottom 4px
        text-align center
        span
          margin-right 8px
          &:last-child
            margin-right 0
  .menu
    margin 24px
    .o-button
      margin-bottom 24px
      text-align center
      text-transform uppercase
