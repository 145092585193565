









































































@import '~variables'

.zone-item
  position relative
  display flex
  align-items center
  padding 4px 32px
  &--chip
    padding 4px 4px
  &--hover
    background-color $light
  .drawer
    position absolute
    top -32px
    right 100%
    transition all 0.3s ease
  .zone-item__form
    margin 16px
  .zone-info
    display flex
    padding 0
    width 100%
    height 100%
    border-top-left-radius 0px
    border-bottom-left-radius 0px
    background-color white
    &--chip
      width 100%
    &--active
      border 2px solid black
    &--active-chip
      box-shadow 0px 0px 0px 2px black
    .zone-info__color
      display inline-block
      display flex
      justify-content center
      align-items center
      width 25px
      height @width
      cursor pointer
      &--editing
        border-right 2px solid black
    .zone-info__name
      display inline-flex
      align-items center
      padding 4px 0
      padding-left 8px
      background-clip text
      font-weight 400
      -webkit-background-clip text !important
      &--chip
        padding 4px 8px
  .zone-action
    display flex
    justify-content center
    align-items center
    width 40%
    user-select none
    span
      margin-right 4px
      &:last-child
        margin-right 0
    .zone-action__separator
      width 1px
      height 12px
      background-color black
    .zone-action__action
      padding 1px
      cursor pointer
      &:hover
        font-weight 700

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
