


















































































@import '~variables'

.property__item
  padding 20px 0
  border-top solid 1px $light
  .property__name
    margin-bottom 10px
    margin-left 20px
    text-transform uppercase
    font-size $fs-h2
  .property__values
    margin-top 10px
