@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.parcel-edition {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.parcel-edition .parcel-edition__button {
  margin-bottom: 16px;
  margin-left: 33px;
  width: 49%;
  font-size: 1rem;
}
.parcel-edition .parcel-edition__button >>> .chip__value {
  justify-content: start;
  padding-left: 7px;
}
.parcel-edition .drawer {
  position: absolute;
  top: 150px;
  right: 100%;
  padding: 16px;
}
.parcel-edition .parcel-edition__title {
  margin: 16px 32px 16px;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1;
}
.parcel-edition .zone-select {
  background-color: #000;
  color: #fff;
}
.parcel-edition .zone-select .q-list {
  background-color: #008000;
}
.parcel-edition .zone-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.parcel-edition .zone-list .select {
  margin-bottom: 16px;
  margin-left: 32px;
  width: 49%;
}
.parcel-edition .zone-list .zone-floor {
  margin-bottom: 16px;
}
.parcel-edition .zone-list .zone-floor:last-child {
  margin-bottom: 0;
}
.parcel-edition .zone-list .floor-name {
  margin-bottom: 16px;
  margin-left: 32px;
  font-weight: 700;
  font-size: 1.1rem;
}
.parcel-edition .separator {
  margin: 8px 0;
  width: 100%;
  height: 1px;
  background-color: #ddd9d3;
}
.parcel-edition .show-zone-btn--active >>> .o-button {
  background-color: #000;
  color: #fff;
}
/*# sourceMappingURL=src/app/pages/building-configuration/views/parcel-edition.css.map */