



















































































@import '~variables'

.conf-step
  display flex
  flex-direction column
  padding 24px
  width 100%
  height 300px
  background black
  color white
  .conf-step__header
    display flex
    flex 8
    .current-step
      font-weight 100
      font-size 6rem
      line-height 0.95em
    .title
      margin-top 25px
      margin-left 50px
      font-size $fs-h1
  .conf-step__main
    flex 7
    .description
      font-size $fs-h1
  .conf-step__footer
    flex 2
    .button
      width 100%
      background-color $dark
      color $light
      font-weight 700
      transition all 200ms ease
      &--active
        background-color white
        color black
        transition all 200ms ease
