@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.conf-step {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  height: 300px;
  background: #000;
  color: #fff;
}
.conf-step .conf-step__header {
  display: flex;
  flex: 8;
}
.conf-step .conf-step__header .current-step {
  font-weight: 100;
  font-size: 6rem;
  line-height: 0.95em;
}
.conf-step .conf-step__header .title {
  margin-top: 25px;
  margin-left: 50px;
  font-size: 1.6rem;
}
.conf-step .conf-step__main {
  flex: 7;
}
.conf-step .conf-step__main .description {
  font-size: 1.6rem;
}
.conf-step .conf-step__footer {
  flex: 2;
}
.conf-step .conf-step__footer .button {
  width: 100%;
  background-color: #464749;
  color: #f3f1ef;
  font-weight: 700;
  transition: all 200ms ease;
}
.conf-step .conf-step__footer .button--active {
  background-color: #fff;
  color: #000;
  transition: all 200ms ease;
}
/*# sourceMappingURL=src/app/pages/building-configuration/components/steps.css.map */