@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.properties-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.properties-sidebar .properties-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
}
.properties-sidebar .properties-container .properties-title {
  display: flex;
  padding: 20px 20px 35px 20px;
  width: 100%;
  border-bottom: solid 1px #b4b2a9;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.6rem;
}
.properties-sidebar .properties-container .space-list__drawer {
  position: absolute;
  top: 0px;
  right: 350px;
  padding: 10px;
  max-width: 300px;
  width: fit-content;
  transition: all 0.3s ease;
}
.properties-sidebar .properties-container .space-list__drawer .space-list {
  display: flex;
  flex-wrap: wrap;
}
.properties-sidebar .properties-container .space-list__drawer .space-list .space-name {
  margin-right: 5px;
  font-size: 1rem;
}
.properties-sidebar .properties-container .properties__no-select {
  margin-top: 20px;
  text-align: center;
  font-weight: 700;
  font-size: 1.3rem;
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/*# sourceMappingURL=src/app/pages/building-configuration/views/properties-sidebar.css.map */