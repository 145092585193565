
























































































































































































































































@import '~variables'

.parcel-edition
  position relative
  display flex
  flex-direction column
  height 100%
  .parcel-edition__button
    margin-bottom 16px
    margin-left 33px
    width 49%
    font-size $fs-body
    & >>> .chip__value
      justify-content start
      padding-left 7px
  .drawer
    position absolute
    top 150px
    right 100%
    padding 16px
  .parcel-edition__title
    margin 16px 32px 16px
    font-weight 700
    font-size $fs-h1
    line-height 1
  .zone-select
    background-color black
    color white
    .q-list
      background-color green
  .zone-list
    display flex
    flex-direction column
    overflow-y auto
    .select
      margin-bottom 16px
      margin-left 32px
      width 49%
    .zone-floor
      margin-bottom 16px
      &:last-child
        margin-bottom 0
    .floor-name
      margin-bottom 16px
      margin-left 32px
      font-weight 700
      font-size $fs-h3
  .separator
    margin 8px 0
    width 100%
    height 1px
    background-color $silver
  .show-zone-btn--active
    >>> .o-button
      background-color black
      color white
