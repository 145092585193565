



































































@import '~variables'

.properties-sidebar
  display flex
  flex-direction column
  height 100%
  .properties-container
    display flex
    flex-grow 1
    flex-direction column
    overflow-y auto
    .properties-title
      display flex
      padding 20px 20px 35px 20px
      width 100%
      border-bottom solid 1px $grey
      text-transform uppercase
      font-weight 700
      font-size $fs-h1
    .space-list__drawer
      position absolute
      top 0px
      right 350px
      padding 10px
      max-width 300px
      width fit-content
      transition all 0.3s ease
      .space-list
        display flex
        flex-wrap wrap
        .space-name
          margin-right 5px
          font-size 1rem
    .properties__no-select
      margin-top 20px
      text-align center
      font-weight 700
      font-size $fs-h2

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
