@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.building-conf-menu .building-information {
  padding: 16px;
  background-color: #000;
  color: #fff;
}
.building-conf-menu .building-information .building-name {
  margin: 0;
  margin-bottom: 16px;
  text-align: center;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1;
}
.building-conf-menu .building-information .building3d {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}
.building-conf-menu .building-information .address {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
}
.building-conf-menu .building-information .address .address-row {
  margin-bottom: 4px;
  text-align: center;
}
.building-conf-menu .building-information .address .address-row span {
  margin-right: 8px;
}
.building-conf-menu .building-information .address .address-row span:last-child {
  margin-right: 0;
}
.building-conf-menu .menu {
  margin: 24px;
}
.building-conf-menu .menu .o-button {
  margin-bottom: 24px;
  text-align: center;
  text-transform: uppercase;
}
/*# sourceMappingURL=src/app/pages/building-configuration/components/building-info.css.map */