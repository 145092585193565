@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.building-configuration {
  min-height: auto;
  height: 100%;
}
.building-configuration .sidebar >>> aside {
  display: flex;
  flex-direction: column;
  overflow: visible;
}
.building-configuration .building-configuration__building {
  padding-top: 0.1px;
  width: 100%;
  height: 100%;
}
.building-configuration .building-configuration__building .nav-info {
  margin: 16px 16px 0;
}
.building-configuration .building-configuration__building .viewer {
  position: relative;
  height: calc(100% - 51px);
  background-color: #ddd9d3;
}
.building-configuration .building-configuration__building .viewer .building-list,
.building-configuration .building-configuration__building .viewer .building-viewer {
  height: 100%;
}
.building-configuration .building-configuration__building .viewer .loading__spinner {
  stroke: #000;
  fill: #000;
}
.building-configuration .building-configuration__building .loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.building-configuration .building-configuration__building .loading-container .loading__message {
  padding: 15px;
  width: 100%;
  color: #000;
  text-align: center;
  font-size: 1.6rem;
}
.back-button-arrow {
  transform: rotate(90deg);
}
.building-preferences {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  margin: 24px;
}
.building-preferences .button-block {
  display: flex;
  flex-direction: row;
}
.building-preferences .button-block .info {
  width: 50px;
  text-align: center;
  padding-top: 3px;
}
/*# sourceMappingURL=src/app/pages/building-configuration/index.css.map */