@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.parcel-info {
  padding: 16px;
  background-color: #000;
  color: #fff;
}
.parcel-info .advice {
  display: flex;
  align-items: center;
  height: 64px;
  font-weight: 700;
  font-size: 1.3rem;
}
.parcel-info .info {
  display: flex;
  flex-direction: column;
}
.parcel-info .info .info-row {
  display: flex;
  margin-bottom: 8px;
}
.parcel-info .info .info-row .info-row__label {
  flex-basis: 20%;
  text-transform: uppercase;
}
.parcel-info .info .info-row .info-row__value {
  flex-basis: 80%;
  margin-left: 8px;
  font-weight: 700;
  font-size: 1.1rem;
}
.parcel-info .info .info-row:last-child {
  margin-bottom: 0;
}
/*# sourceMappingURL=src/app/pages/building-configuration/components/parcel-info.css.map */