

















































































@import '~variables'

.parcel-info
  padding 16px
  background-color black
  color white
  .advice
    display flex
    align-items center
    height 64px
    font-weight 700
    font-size $fs-h2
  .info
    display flex
    flex-direction column
    .info-row
      display flex
      margin-bottom 8px
      .info-row__label
        flex-basis 20%
        text-transform uppercase
      .info-row__value
        flex-basis 80%
        margin-left 8px
        font-weight 700
        font-size $fs-h3
      &:last-child
        margin-bottom 0
