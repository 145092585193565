@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.zone-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 32px;
}
.zone-item--chip {
  padding: 4px 4px;
}
.zone-item--hover {
  background-color: #f3f1ef;
}
.zone-item .drawer {
  position: absolute;
  top: -32px;
  right: 100%;
  transition: all 0.3s ease;
}
.zone-item .zone-item__form {
  margin: 16px;
}
.zone-item .zone-info {
  display: flex;
  padding: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #fff;
}
.zone-item .zone-info--chip {
  width: 100%;
}
.zone-item .zone-info--active {
  border: 2px solid #000;
}
.zone-item .zone-info--active-chip {
  box-shadow: 0px 0px 0px 2px #000;
}
.zone-item .zone-info .zone-info__color {
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.zone-item .zone-info .zone-info__color--editing {
  border-right: 2px solid #000;
}
.zone-item .zone-info .zone-info__name {
  display: inline-flex;
  align-items: center;
  padding: 4px 0;
  padding-left: 8px;
  background-clip: text;
  font-weight: 400;
  -webkit-background-clip: text !important;
}
.zone-item .zone-info .zone-info__name--chip {
  padding: 4px 8px;
}
.zone-item .zone-action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  user-select: none;
}
.zone-item .zone-action span {
  margin-right: 4px;
}
.zone-item .zone-action span:last-child {
  margin-right: 0;
}
.zone-item .zone-action .zone-action__separator {
  width: 1px;
  height: 12px;
  background-color: #000;
}
.zone-item .zone-action .zone-action__action {
  padding: 1px;
  cursor: pointer;
}
.zone-item .zone-action .zone-action__action:hover {
  font-weight: 700;
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/*# sourceMappingURL=src/app/pages/building-configuration/components/property-value-item.css.map */